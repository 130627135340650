import React from 'react';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';

import { UserPropertiesContainer } from '@pages/createExperiment/targetConfig/userProperties/UserPropertiesContainer';

import { ArpuInput } from '@pages/experimentDetails/components/summary/config/definition/inputs/ArpuInput';
import { ReportingArpuInput } from '@pages/experimentDetails/components/summary/config/definition/inputs/ReportingArpuInput';
import { CheckPeriodInput } from '@pages/experimentDetails/components/summary/config/definition/inputs/CheckPeriodInput';
import { AutomaticModeInput } from '@pages/experimentDetails/components/summary/config/definition/inputs/AutomaticModeInput';
import { AppVersionInput } from '@pages/experimentDetails/components/summary/config/definition/inputs/AppVersionInput';

import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';

import styles from '@pages/experimentDetails/ExperimentDetails.module.scss';

type Props = {
  control: Control<ExpDefinitionParams>;
  register: UseFormRegister<ExpDefinitionParams>;
  errors: FieldErrors<ExpDefinitionParams>;
  watch: UseFormWatch<ExpDefinitionParams>;
  setValue: UseFormSetValue<ExpDefinitionParams>;
  userProperties: UserProperty[];
  operators: UserPropertyOperator[];
};

export function ExpDefinitionEditor({ register, errors, control, watch, setValue, userProperties, operators }: Props) {
  return (
    <ul className={styles.editorList}>
      <li>
        <ArpuInput register={register} errors={errors} />
      </li>
      <li>
        <ReportingArpuInput register={register} errors={errors} control={control} />
      </li>
      <li>
        <CheckPeriodInput control={control} />
      </li>
      <li>
        <AutomaticModeInput register={register} errors={errors} />
      </li>

      <li>
        <AppVersionInput control={control} register={register} errors={errors} />
      </li>

      <li>
        <div className={styles.editorInputLabel}>
          <p>User Properties:</p>
        </div>
        <div>
          {/* todo: cu-1555, figure out how to use set generic type for useForm components*/}
          <UserPropertiesContainer
            watch={watch as unknown as UseFormWatch<TargetConfigParams>}
            control={control as unknown as Control<TargetConfigParams>}
            setValue={setValue as unknown as UseFormSetValue<TargetConfigParams>}
            userProperties={userProperties}
            operators={operators}
            watchName="userProperties.inputs"
            hideLabel
          />
        </div>
      </li>
    </ul>
  );
}
