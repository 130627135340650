import React, { useCallback } from 'react';
import { Button, ButtonVariant, InputLabel } from 'crazy-ui-next';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { remove } from 'lodash-es';

import { UserPropertiesInput } from '@pages/createExperiment/targetConfig/userProperties/UserPropertiesInput';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { UserProperty } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';
import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';

type Props = {
  watch: UseFormWatch<TargetConfigParams>;
  control: Control<TargetConfigParams>;
  setValue: UseFormSetValue<TargetConfigParams>;
  hideLabel?: boolean;
  userProperties: UserProperty[];
  operators: UserPropertyOperator[];
  watchName: string;
};

export function UserPropertiesContainer({
  userProperties,
  operators,
  watch,
  control,
  setValue,
  hideLabel = false,
  watchName
}: Props) {
  // @ts-ignore
  const inputs = watch(watchName);

  const onAddRow = useCallback(
    (currentRowIndex?: number) => {
      if (currentRowIndex === undefined) {
        // @ts-ignore
        setValue(`${watchName}.0`, {
          userPropertyId: '',
          userPropertyDisplayName: '',
          operatorId: String(operators[0].id),
          operatorDisplayName: operators[0].displayName,
          value: ''
        });
        return;
      }

      const nextRowIndex = currentRowIndex + 1;
      const nextUserProperty = userProperties[nextRowIndex] || null;

      if (nextUserProperty) {
        // @ts-ignore
        setValue(`${watchName}.${nextRowIndex}`, {
          userPropertyId: '',
          userPropertyDisplayName: '',
          operatorId: String(operators[0].id),
          operatorDisplayName: operators[0].displayName,
          value: ''
        });
      }
    },
    [watchName, setValue, userProperties, operators]
  );

  const onRemoveRow = useCallback(
    (index: number) => {
      const updatedInputs = remove(inputs, (input, i) => i !== index);

      // @ts-ignore
      setValue(watchName, updatedInputs);
    },
    [watchName, setValue, inputs]
  );

  if (!inputs.length) {
    return (
      <>
        {!hideLabel && <InputLabel labelText="User Properties" />}
        <Button variant={ButtonVariant.TERTIARY} onClick={() => onAddRow()} icon={<PlusSvg />} />
      </>
    );
  }

  const rows = inputs.map((input, index) => {
    return (
      <UserPropertiesInput
        key={`user_properties_input_${index}`}
        userProperties={userProperties}
        operators={operators}
        control={control}
        inputIndex={index}
        onAddRow={onAddRow}
        onRemoveRow={onRemoveRow}
        watch={watch}
        watchName={watchName}
        setValue={setValue}
      />
    );
  });

  return (
    <>
      {!hideLabel && <InputLabel labelText="User Properties" />}
      {rows}
    </>
  );
}
