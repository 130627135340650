import { Service } from 'typedi';

import { GameUseCase } from '@domain/useCases/GameUseCase';
import { GameApi } from '@infrastructure/api/GameApi';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { UniqueId } from '@domain/types';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ILiveConfig } from '@domain/models/experiment/LiveConfig';

@Service()
export class GameService implements GameUseCase {
  constructor(private readonly gameApi: GameApi) {}

  async fetchGameStats(applicationId: UniqueId): Promise<GameStatsDto[]> {
    return this.gameApi.getGameStats(applicationId);
  }

  async fetchInstallsByRegion(applicationId: UniqueId, regionId: UniqueId): Promise<GameInstallsStatsDto> {
    return this.gameApi.getGameInstallsByRegion(applicationId, regionId);
  }

  async fetchLiveConfigs(applicationId: UniqueId, experimentType: ExperimentType): Promise<ILiveConfig[]> {
    return this.gameApi.getLiveConfigs(applicationId, experimentType);
  }
}
