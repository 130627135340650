import React from 'react';
import { Link } from 'react-router-dom';

import { Routing } from '@infrastructure/routing';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ILiveConfig } from '@domain/models/experiment/LiveConfig';
import { OverlappingTypeTitleMap } from '@ui/const/OverlappingTypeTitleMap';
import { VersionOperatorLabels } from '@domain/enums/VersionOperator';

type Props = {
  config: ILiveConfig;
};

export function Row({ config }: Props) {
  const {
    experimentId,
    experimentName,
    type,
    appVersions,
    gameConfigType,
    primaryRegion,
    regions,
    inDevRegions,
    parameters,
    importedSegments,
    userProperties,
    appliedVersionOperator
  } = config;
  const isGLD = type === ExperimentType.GLD_TEST;

  return (
    <li>
      {experimentName && (
        <div>
          <span>Experiment name: </span>
          <span>
            <Link to={Routing.getExperiment(experimentId)}>{experimentName}</Link>
          </span>
        </div>
      )}

      {gameConfigType && (
        <div>
          <span>Overlapping type: </span>
          <span>{OverlappingTypeTitleMap[gameConfigType]}</span>
        </div>
      )}

      {appVersions && (
        <div>
          <span>Version: </span>
          <span>
            {appliedVersionOperator ? VersionOperatorLabels[appliedVersionOperator] : ''} {appVersions.join(', ')}
          </span>
        </div>
      )}

      {primaryRegion && (
        <div>
          <span>Primary region: </span>
          <span>{primaryRegion.join(', ')}</span>
        </div>
      )}

      {regions?.length && (
        <div>
          <span>Live regions: </span>
          <span>{regions.join(', ')}</span>
        </div>
      )}

      {isGLD && inDevRegions?.length > 0 && (
        <div>
          <span>Dev regions: </span>
          <span>{inDevRegions.join(', ')}</span>
        </div>
      )}

      {importedSegments && (
        <div>
          <span>Imported segments: </span>
          <span>{importedSegments}</span>
        </div>
      )}

      {userProperties && (
        <div>
          <span>User properties</span>
          <span>
            {userProperties
              .map(
                (property) => `${property.userPropertyDisplayName} ${property.operatorDisplayName} ${property.value}`
              )
              .join(', ')}
          </span>
        </div>
      )}

      {parameters?.length > 0 && (
        <div>
          <span>Parameters: </span>
          <span>{parameters.join(', ')}</span>
        </div>
      )}
    </li>
  );
}
