import { IsEnum, IsNotEmpty, IsInt, IsOptional, IsString, Matches, MaxLength, IsBoolean } from 'class-validator';

import { ExperimentType } from '@domain/enums/ExperimentType';
import { GamePlatform, GamePlatformTitleMap } from '@domain/enums/GamePlatform';
import { ExpFormSummary } from '@pages/createExperiment/atoms/Summary/Summary';
import { ExpTypeTitleMap } from '@ui/const/ExpTypeTitleMap';

export class BasicInfoParams {
  @IsEnum(ExperimentType)
  @IsNotEmpty()
  experimentType: ExperimentType;

  @IsString()
  @IsNotEmpty()
  @Matches(/^[a-zA-Z0-9-_.\s]*$/, { message: 'Invalid name. Please use [A-Z0-9-_.]' })
  @MaxLength(256)
  experimentName: string;

  @IsString()
  @IsNotEmpty()
  gameName: string;

  @IsInt()
  @IsNotEmpty()
  gameId: number;

  @IsEnum(GamePlatform)
  @IsNotEmpty()
  platform: GamePlatform;

  @IsString()
  @IsNotEmpty()
  bundleId: string;

  @IsString()
  @IsNotEmpty()
  firebaseProjectId: string;

  @IsString()
  @IsOptional()
  description: string;

  @IsBoolean()
  isRecommendedProfile: boolean;

  static ofInitial() {
    const form = new BasicInfoParams();
    form.experimentType = ExperimentType.AB_TESTING;
    form.experimentName = '';
    form.gameName = '';
    form.gameId = 0;
    form.platform = '' as GamePlatform;
    form.bundleId = '';
    form.firebaseProjectId = '';
    form.description = '';
    form.isRecommendedProfile = false;

    return form;
  }

  getSummary(): ExpFormSummary {
    const fields = [
      { title: 'Experiment Type', value: ExpTypeTitleMap[this.experimentType] },
      { title: 'Experiment Name', value: this.experimentName },
      { title: 'Game Name', value: this.gameName },
      { title: 'Platform', value: GamePlatformTitleMap[this.platform] },
      { title: 'Bundle ID', value: this.bundleId },
      { title: 'Firebase Project', value: this.firebaseProjectId }
    ];

    if (this.experimentType === ExperimentType.GLD_TEST) {
      fields.push({ title: 'Description', value: this.description || '-' });
    } else {
      fields.push({ title: 'Use Recommended Profile', value: String(this.isRecommendedProfile) });
    }

    return fields;
  }
}
