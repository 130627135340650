import React from 'react';
import { useController, Control } from 'react-hook-form';
import { TextInput } from 'crazy-ui-next';

import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';

type Props = {
  inputIndex: number;
  control: Control<TargetConfigParams>;
  disabled: boolean;
  watchName: string;
};

export function UserPropertyTextInput({ inputIndex, control, disabled, watchName }: Props) {
  const { field } = useController({
    // @ts-ignore-next-line
    name: `${watchName}.${inputIndex}.value`,
    control
  });

  // @ts-ignore-next-line
  return <TextInput name={field.name} value={field.value} onChange={field.onChange} disabled={disabled} />;
}
