import { Service } from 'typedi';
import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { ResponseMapper } from '@app/mappers/ResponseMapper';
import { UniqueId } from '@domain/types';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ILiveConfig } from '@domain/models/experiment/LiveConfig';

@Service()
export class GameApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getGameStats(applicationId: UniqueId): Promise<GameStatsDto[]> {
    return this.http
      .get(`/intops/admin/gamestats/${applicationId}/revenue`)
      .then((list) => list?.map(ResponseMapper.mapToGameStatsDto) || []);
  }

  async getGameInstallsByRegion(applicationId: UniqueId, regionId: UniqueId): Promise<GameInstallsStatsDto> {
    return this.http
      .get(`/intops/admin/gamestats/${applicationId}/installs`, { regionId })
      .then(ResponseMapper.mapToGameInstallsStatsDto);
  }

  async getGameInstallsByCountries(applicationId: UniqueId, countries: string[]): Promise<GameInstallsStatsDto> {
    return this.http
      .get(`/intops/admin/gamestats/${applicationId}/installs`, { countries })
      .then(ResponseMapper.mapToGameInstallsStatsDto);
  }

  async getLiveConfigs(applicationId: UniqueId, experimentType: ExperimentType): Promise<ILiveConfig[]> {
    return this.http
      .get(`/intops/admin/game/active-configs/${applicationId}`, { experimentType })
      .then(({ gameConfigs }) => gameConfigs);
  }
}
