import React from 'react';
import { useController, Control, UseFormSetValue } from 'react-hook-form';
import { DropdownInput } from 'crazy-ui-next';

import { UserPropertyOperator } from '@domain/models/createExperiment/userProperties/UserPropertyOperatorsDto';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';

import styles from '@pages/createExperiment/targetConfig/userProperties/UserProperties.module.scss';

type Props = {
  inputIndex: number;
  control: Control<TargetConfigParams>;
  setValue: UseFormSetValue<TargetConfigParams>;
  operators: UserPropertyOperator[];
  disabled: boolean;
  watchName: string;
};

export function UserPropertyOperatorInput({ inputIndex, control, operators, setValue, disabled, watchName }: Props) {
  const inputName = `${watchName}.${inputIndex}.operatorId`;

  const options = operators.map((operator) => ({
    label: operator.displayName,
    value: String(operator.id)
  }));

  const { field } = useController({
    // @ts-ignore-next-line
    name: inputName,
    control
  });

  const handleChange = (event) => {
    const { value } = event;
    const operator = operators.find((property) => property.id === +value);
    const displayName = operator ? operator.displayName : '';

    // @ts-ignore-next-line
    setValue(`${watchName}.${inputIndex}.operatorId`, value);
    // @ts-ignore-next-line
    setValue(`${watchName}.${inputIndex}.operatorDisplayName`, displayName);
  };

  return (
    <DropdownInput
      options={options}
      name={inputName}
      value={field.value}
      handleChange={handleChange}
      disabled={disabled}
      className={styles.dropdown}
    />
  );
}
